.animation-container {
  position: absolute;
  height: 90%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  pointer-events: none;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}

.micControl {
  position: relative;
  z-index: 4000;
  height: auto !important;
  width: 200px !important;
  pointer-events: all;
  overflow: hidden;
  display: flex;
  top: calc(100% - 32%);
  justify-content: center !important;
  margin: 0 auto;
}

.micControl-icon {
  height: auto;
  width: 90px;
  pointer-events: all;
  color: rgb(228, 228, 228);
  z-index: 1000;
  position: absolute;
  bottom: 7%;
}

@media only screen and (min-width: 1280px) and (max-width: 1280px) {
  .micControl {
    position: relative !important;
    z-index: 3000;
    height: auto !important;
    width: 200px !important;
    pointer-events: all;
    top: calc(100vh - 38vh);
    display: flex;
    justify-content: center;
  }

  .micControl-icon {
    height: auto !important;
    width: 90px !important;
    pointer-events: all;
    color: transparent;
    background-color: rgba(39, 39, 39, 0);
    border-radius: 50%;
    top: calc(100vh - 29vh);
    position: absolute !important;
    z-index: 1000;
  }
}

@media only screen and (min-width: 1580px) and (max-width: 2280px) {
  .micControl {
    position: relative !important;
    z-index: 3000;
    height: auto !important;
    width: 200px !important;
    pointer-events: all;
    top: calc(100vh - 36vh);
    display: flex;
    justify-content: center;
  }

  .micControl-icon {
    height: auto !important;
    width: 90px !important;
    pointer-events: all;
    color: transparent;
    background-color: rgba(39, 39, 39, 0);
    border-radius: 50%;
    top: calc(100vh - 29vh);
    position: absolute !important;
    z-index: 1000;
  }
}

.micControl-label-container{
  position: absolute;
  height: 90%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  pointer-events: none;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}

