.organizations_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Block: organizations */
.organizations {
  display: block !important;
  margin-top: 100px !important;
  padding: 32px 24px 27px 24px;
  background: linear-gradient(346deg, #030b16 3.08%, #1b1c3f 97.1%, #8066ec 137.39%);
  border-radius: 1%;
}

@media only screen and (max-width: 980px) {
  .organizations {
    padding: 32px 24px 27px 24px;
  }
}

/* Element: title */
.organizations__title {
  font-family: Figtree !important;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

/* Element: text */
.organizations__text {
  opacity: 0.5;
  font-family: Figtree;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

/* Element: list */
.organizations__list {
  margin-bottom: 32px;
}

.other_organizations_list {
  position: relative;

  .header{
    font-family: Figtree !important;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
  }
}

/* Block: organization */
.organization {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 12px 0;
  border-bottom: solid 1px rgba(128, 102, 236, 0.1);
  width: 100%;
}

/* Element: info */
.organization__info {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

/* Element: name */
.organization__name {
  font-family: Figtree;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
  text-align: left;
}

/* Element: id */
.organization__id {
  height: 19px;
  opacity: 0.5;
  font-family: Figtree;
  font-size: 16px;
  text-align: left;
  color: #fff;
  margin-bottom: 0;
}

/* Element: icon */
.organization__icon {
  margin-right: 16px;
}

/* Block: organization-button */
.organization-button {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px 24px 48px;
}
