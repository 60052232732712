@keyframes animate-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.account-settings {
    display: flex !important;
    align-items: center;
    justify-items: center;
    justify-content: flex-start;
    background: linear-gradient(346deg, #030B16 3.08%, #1B1C3F 97.1%, #8066EC 137.39%);
    flex-direction: column;
    height: 100%;
}


@media only screen and (max-height: 660px) {
    .account-settings {
        height: 100%;
    }
}

@media only screen and (max-width: 1280px) {
    .account-settings {
        height: 61em;
    }
}

@media only screen and (max-height: 1290px) {
    .account-settings {
        height: 100%;
    }
}

@media only screen and (max-height: 900px) {
    .account-settings {
        height: 57em;
    }
}

.account-settings .title-name {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
}

.account-settings .subtitle {
    font-size: 16px;
}

.label-notification {
    font-size: 20px;
    font-weight: bold;
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding-top: 50px;
}

.account-settings p {
    color: rgb(255, 255, 255);
}

.account-settings p span {
    color: rgb(255, 255, 255);
    text-decoration: underline;
}

.account-settings .settings-form {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 20px;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border-radius: 5px;
}

.settings-form label span {
    color: rgb(245, 245, 245);
}

.settings-form label {
    color: rgb(245, 245, 245);
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
}

.settings-form .MuiOutlinedInput-input {
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: None;
    color: rgb(224, 224, 224) !important;
    border-radius: 5px;
    padding: 5px;
}

.css-1yjjitx-MuiSwitch-track {
    background-color: #fff !important
}

.save-button {
    width: 100% !important;
    border-radius: 8px !important;
    background-color: #8066ec !important;
    color: rgb(255, 255, 255) !important;
    padding: 10px !important;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.7s;
    animation-fill-mode: backwards;
}

.account-settings .MuiInputBase-input {
    height: 15px;
    align-self: stretch;
    color: #fff;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 16px 24px;
    border-radius: 8px;
    border: solid 1px #8066ec;
    background-color: rgba(128, 102, 236, 0.1);
}

.account-settings .MuiFormControl-root .MuiInputBase-root .Mui-disabled {
    -webkit-text-fill-color: #fff !important;
}
