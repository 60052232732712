.logo-container {
    align-items: center;
    justify-content: center;
    display: flex;
}

.logo-container .form-logo {
    position: relative;
    max-width: 125px;
    z-index: 11;
}

.faqs-container .MuiPaper-root {
    box-shadow: none !important;
}

.faqs-container {
    color: #585858;
    padding: 10px 10px;
    background: linear-gradient(346deg, #030B16 3.08%, #1B1C3F 97.1%, #8066EC 137.39%);
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
}


@media only screen and (max-height: 700px) {
    .faqs-container {
        height: 120%;
    }
}
