.link_mediator_container{
    background: linear-gradient(346deg, #030B16 3.08%, #1B1C3F 97.1%, #8066EC 137.39%);
    padding: 10px;
    padding-top: 50px;
}

.title {
    font-family: Figtree;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
}

.text{
    opacity: 0.5;
    font-family: Figtree;
    font-size: 18px;
    text-align: center;
    color: #fff;
}

.mediator_info_container{
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.info_item{
    font-family: Figtree;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}

.link-mediator-button-container {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 32px 24px 48px;
}
