.feedback-buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 450px;
    padding: 10px 0px;
    justify-content: center;
}

.feedback-fab {
    box-shadow: none !important;
    background-color: rgb(0 0 0 / 52%) !important;
    height: 40px !important;
    width: 40px !important;
    min-height: 20px !important;
    margin-right: 8px !important;
    padding: 13px !important;
    border: solid 1px #82828280 !important;
}


.feedback-icons {
    height: 15px !important;
    color: rgba(240, 248, 255, 0.553);
}

.feedback-fab.selected {
    background-color: rgba(255, 253, 247, 0.079) !important;
}


.feedback-fab.selected .feedback-icons {
    color: rgb(255, 255, 255) !important;
    animation-name: teeter;
    animation-duration: 0.1s;
    animation-iteration-count: 5;
    animation-timing-function: ease-in-out;
}

.animationfb {
    position: absolute !important;
    height: 200px;
    width: 200px;
}



@keyframes teeter {

    0% {}

    33% {
        transform: translate(-7%, 0) rotate(-9deg);
    }

    66% {
        transform: translate(-5%, 0) rotate(4deg);
    }

    66% {
        transform: translate(7%, 0) rotate(9deg);
    }
}


.feedback-dash .MuiOutlinedInput-input {
    background-color: white !important;
    color: black !important;
}

.feedback-dash .Mui-focused {
    color: black !important;
    border-color: black !important;
}
