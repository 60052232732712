.share-container {
    /* background: linear-gradient(346deg, #030B16 3.08%, #1B1C3F 97.1%, #8066EC 137.39%); */
}

.share-container .MuiPaper-root {
    background: linear-gradient(346deg, #030B16 3.08%, #1B1C3F 97.1%, #8066EC 137.39%);
    width: 95%;
    padding: 2rem;
    min-height: 50vh;

    @media screen and (min-width: 992px) {
        width: 50%;
        transform: translateX(50%);
        left: auto;
    }
}

.share-container .facebook,
.share-container .email,
.share-container .linkedin,
.share-container .whatsapp {
    width: 80%;
    margin: 1rem 0;
    width: 100%;
    color: #fff !important;
    padding: 16px !important;
    border-radius: 8px;
    height: 54px;
}
.share-container .facebook {
    background-color: #405caa !important;
}
.share-container .email {
    background-color: #6a7683 !important;
}
.share-container .whatsapp {
    background-color: #269202 !important;
}
.share-container .linkedin {
    background-color: #0a66c2 !important;
}
