

.title{
  text-align: center;
  margin-bottom: 20px;
  font-family:Figtree-Regular !important ;
}
@media only screen and (max-width: 430px) {
    .pincode-input-container{
    z-index: 10;
    }
    .button-container{
          padding: 30px !important;
          height: 100%;
    }

}

.button-container{
    padding: 30px !important;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    flex-direction: column;
}

.main {
    position: absolute;
    display: flex;
    align-content: center;
    height: 100%;
    justify-items: center;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    max-height: 100%;
    flex-direction: column;
}

.buttons-state{
    font-family:Figtree-Regular !important;
    pointer-events:auto;
    cursor: pointer;
    background-color: rgba(10, 10, 10, 0.2) !important;
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
    box-sizing: border-box !important;
    border-radius: 60px !important;
    padding: 0px 12px;
    color: white !important;
    transition: opacity 0.3s ease 0s;
    min-width: 100%;
    width: 100%;
    font-size:17px !important;
    flex: 1% 1% 1%;
    display: flex;
    -webkit-box-align: left;
    align-items: left;
    min-height: 52px;
    margin: 5px 5px !important;
    text-align:left !important;
    justify-content: left !important;
    text-transform: initial !important;
}

@font-face {
  font-family: 'Figtree-Regular';
  src: local('Figtree-Regular'), url(../fonts/Figtree-Regular.ttf) format('woff');
}

body{
  font-family:Figtree-Regular !important ;
}
