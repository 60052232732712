.css-dsiexi-MuiCardActions-root {
    justify-content: space-between !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #8066ec;
}

.subscription .css-ahj2mt-MuiTypography-root {
    color: black; 
}

.subscription {
    background: linear-gradient(346deg, #030B16 3.08%, #1B1C3F 97.1%, #8066EC 137.39%);
    height: 100vh;
    padding: 80px 25px;
    overflow: scroll
}

.subscription .subscription-title {
    color: #fff;
}

.subscription .description {
    color: #fff;
}