@font-face {
  font-family: "Figtree";
  src: local("Figtree-Regular"),
    url(../fonts/Figtree-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Figtree";
  src: local("Figtree-Bold"), url(../fonts/Figtree-Bold.ttf) format("truetype");
  font-weight: bold;
}

/* body {
  font-family: 'Figtree', sans-serif !important;
} */

.main-container {
  background: linear-gradient(346deg,
      #030b16 3.08%,
      #1b1c3f 97.1%,
      #8066ec 137.39%);
}

.fade-in {
  animation-duration: 1s;
  animation-name: animate-fade-in;
  animation-delay: 0s;
  animation-fill-mode: backwards;
}

.fade-out {
  animation-duration: 1s;
  animation-name: animate-fade-out;
  animation-delay: 0s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animate-fade-out {
  100% {
    opacity: 0;
  }

  0% {
    opacity: 1;
  }
}

.MuiButton-containedPrimary {
  color: #fff;
  background-color: #e91c1c !important;
  text-transform: capitalize !important;
}

.updates-container .MuiPaper-root,
.faqs-container .MuiPaper-root {
  z-index: 120000 !important;
  background-color: transparent !important;
}

@media only screen and (min-width: 1280px) {
  .faqs-container {
    overflow-y: auto;
    max-height: 120vh;
  }
}

.main-navigation-container .MuiPaper-root {
  background: linear-gradient(346deg,
      #030b16 3.08%,
      #1b1c3f 97.1%,
      #8066ec 137.39%);
}

.tooltip-ct {
  background-color: white;
  margin: 0px 0px;
  border-radius: 5px;
  padding: 10px 5px;
}

.tooltip-close {
  float: right;
  margin-right: -20px !important;
  margin-top: -10px !important;
  color: black !important;
  border: solid 1px black;
  border-radius: 50%;
}

p div {
  white-space: pre-line;
}

a {
  text-decoration: none;
  color: black;
}

.app-logo {
  width: 15em;
}

.splash-screem {
  background: linear-gradient(346deg,
      #030b16 3.08%,
      #1b1c3f 97.1%,
      #8066ec 137.39%);
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.splash-screem .splash-container {
  width: 70%;
  align-items: center;

  @media (min-width: 992px) {
    padding: 30px;
  }
}

.gogin-btn {
  width: 100%;
  max-width: 240px;
  font-size: 15px !important;
  background-color: #8066ec !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600 !important;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.7s;
  animation-fill-mode: backwards;
  text-transform: unset !important;
  gap: 10px;
  padding: 16px 48px;
  border-radius: 8px;
}

.MuiTypography-subtitle1 {
  display: none;
}

.app-title {
  flex-grow: 0;
  font-size: 2rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin: 5rem 0 !important;
}

.terms .MuiButton-textPrimary,
.btn-primary {
  width: 100%;
  max-width: 240px;
  font-size: 0.938rem !important;
  background-color: #8066ec !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600 !important;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.7s;
  animation-fill-mode: backwards;
  text-transform: unset !important;
  gap: 10px;
  padding: 16px 48px;
  border-radius: 8px;
}

.btn-secondary {
  height: 56px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 48px;
  border-radius: 8px !important;
  border: solid 1px rgba(255, 255, 255, 0.5) !important;
  background-color: transparent !important;
  width: 100%;
}

.btn-tertiary {
  font-weight: bold;
  font-style: italic;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.custom-border {
  border: solid 1px #8066ec !important;
  border-radius: 8px;
}

.custom-border input {
  border: 0 !important;
}

.custom-border .MuiSvgIcon-root,
.updates-container .MuiSvgIcon-root,
.faqs-container .MuiSvgIcon-root,
.main-navigation-container .MuiSvgIcon-root,
.password-field .MuiSvgIcon-root {
  fill: #fff !important;
}

.check .MuiSvgIcon-root {
  fill: #8066ec !important;
}

.main-navigation-container .MuiListItemText-root span {
  color: white !important;
}

.nav-header {
  margin: 1.80rem, auto
}

.account-title {
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
  font-family: Figtree-Bold;
}

.account-link {
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  text-decoration: underline;
  font-style: italic;
  margin-bottom: 0;
  cursor: pointer;
}

.css-h4y409-MuiList-root {
  padding: 0;
}

.updates-container {
  color: #fff;
  padding: 10px 10px;
  background: linear-gradient(346deg,
      #030b16 3.08%,
      #1b1c3f 97.1%,
      #8066ec 137.39%);
  min-height: 100vh;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.updates-container .title {
  margin: 70px 0px 20px 0px;
}

.updates-container .accordion-title {
  color: #fff;
  font-size: 18px;
}

.updates-container .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background: transparent !important;
  box-shadow: none !important;
}

.updates-container .content {
  color: #fff;
}

.updates-container .MuiAccordion-root {
  margin: 0;
}

.terms,
.MuiDialog-paper {
  background: linear-gradient(346deg,
      #030b16 3.08%,
      #1b1c3f 97.1%,
      #8066ec 137.39%);
}

.terms .MuiButton-textPrimary {
  @media only screen and (max-width: 768px) {
    max-width: none !important;
  }
}

.terms,
.MuiDialog-container .MuiDialogActions-root .MuiBox-root,
.MuiDialog-paper .MuiDialogContent-root,
.MuiDialog-paper .MuiDialogTitle-root {
  color: #fff !important;
}

.link-org .MuiButton-textPrimary,
.link-org .MuiButton-outlinedPrimary,
.MuiDialog-container .MuiDialogActions-root .MuiButtonBase-root,
.MuiDialog-container .MuiButton-containedPrimary {
  background-color: #8066ec !important;
}

.link-org .MuiButton-outlinedPrimary,
.link-org .MuiButton-textPrimary {
  color: #fff !important;
  width: 100%;
}

.MuiBox-root.css-1sxpmq1 {
  width: 25rem !important;
}
