.close {
  position: absolute !important;
  color: rgb(255, 255, 255) !important;
  background: rgba(3, 3, 3, 0.253) !important;
  border-color: rgba(255, 255, 255, 0.137) !important;
  margin-left: 74% !important;
  margin-right: 15% !important;
  margin-top: 5% !important;
  z-index: 1000;
}

.icon {
  background-color: rgba(0, 0, 0, 0.438);
  border-radius: 50%;
  color: rgb(255, 255, 255);
  padding: 2px 5px !important;
}

.close span {
  padding: 5px;
}

.stack {
  display: grid;
  align-content: center;
  align-items: center;
  justify-items: center;
}

.button-inquiry {
  background-color: rgb(225, 35, 19) !important;
  color: white !important;
  width: 60%;
  border-radius: 25px !important;
  margin-top: 70px !important;
  font-weight: 100;
  max-width: 150px;
}

@media only screen and (max-width: 430px) {
  .close {
    margin-left: 74% !important;
  }
}

@media only screen and (min-width: 480px) {
  .close {
    margin-left: 81% !important;
  }
}

@media only screen and (max-width: 520px) {
  .close {
    margin-left: 75% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .close {
    margin-left: 82% !important;
  }
}

.swipe-animation {
  position: absolute;
  z-index: 10;
  pointer-events: none;
  height: 190px !important;
  margin-top: 120%;
}

.mode-control {
  position: absolute !important;
  color: #fffefee3 !important;
  background: #00000031 !important;
  border-color: rgba(255, 255, 255, 0.795) !important;
  margin-left: 78%;
  margin-top: 10%;
  margin-bottom: 7px;
  margin-right: 7px;
  z-index: 1000;
  border-radius: 50%;
  box-shadow: #0000005a 1px 1px 3px;
  font-size: 3rem !important;
  overflow: visible;
  border-width: 2px;
  padding: 4px;
}

@media only screen and (max-width: 430px) {
  .mode-control {
    margin-left: 85% !important;
    margin-top: 20% !important;
  }
}

@media only screen and (min-width: 480px) {
  .mode-control {
    margin-left: 85% !important;
    margin-top: 12% !important;
  }
}

@media only screen and (min-width: 720px) {
  .mode-control {
    margin-left: 85% !important;
    margin-top: 12% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .mode-control {
    margin-left: 85% !important;
    margin-top: 8% !important;
  }
}

.react-joyride__beacon {
  margin-left: 50px;
  margin-bottom: 50px;
}

.current-page-container {
  width: 100%;
  position: absolute !important;
  z-index: 1200;
  bottom: 5rem;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
}

@media only screen and (max-width: 950px) {
  .current-page-container {
    height: 100% !important;
  }
}

@media only screen and (max-width: 1280px) {
  .current-page-container {
    height: 120% !important;
  }
}

@media only screen and (max-width: 1390px) {
  .current-page-container {
    height: 100% !important;
  }
}
